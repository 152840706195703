import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material";
import * as filestack from "filestack-js";
import * as XLSX from "xlsx";
import { api } from "../../../axios/api";
import { useSelector } from "react-redux";
import PerformanceComparisonModal from "./PerformanceComparisonModal";

const ViewReports = ({ leads, reportType, setShowReport }) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [downloadFileUrl, setDownloadFileUrl] = useState("");
  const [averageActionDays, setAverageActionDays] = useState("");
  const [averageNoOfNotes, setAverageNoOfNotes] = useState("");
  const [organizationInformation, setOrganizationInformation] = useState();

  useEffect(() => {
    api
      .get("/organization")
      .then((res) => {
        console.log("res?.data", res?.data);
        let org = res?.data;
        if (org?.organization_currency) {
          setOrganizationInformation(org);
        } else {
          setOrganizationInformation(res?.data[0]);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    console.log("report data", leads);
    console.log("report type", reportType);

    if (reportType === "Leads by number of days open") {
      setData(
        leads
          .map((d) => {
            // Convert 'total_open_days' to minutes
            const timeArray = d.total_open_days.split(" ");
            let totalMinutes = 0;
            if (timeArray.includes("days")) {
              totalMinutes += parseInt(timeArray[0]) * 24 * 60;
            }
            if (timeArray.includes("hours")) {
              totalMinutes += parseInt(timeArray[2]);
            }
            if (timeArray.includes("minutes")) {
              totalMinutes += parseInt(timeArray[4]);
            }
            return {
              totalOpenDays: d?.total_open_days,
              leadSerialNumber: d?.lead_serial_number,
              contactPerson: d?.contact_person,
              email: d?.email,
              organizationName: d?.organization_name,
              leadSource: d?.lead_source,
              serviceNeeded: d?.service_needed,
              valueOfLead: d?.value_of_lead,
              totalMinutes: totalMinutes, // New field for sorting
            };
          })
          .sort((a, b) => a.totalMinutes - b.totalMinutes)
          .map(({ totalMinutes, ...rest }) => rest) // Remove 'totalMinutes' key
      );
    }
    if (reportType == "Leads by point rating") {
      console.log("hurrr");
      setData(
        leads
          .map((d) => {
            return {
              points: d?.points,
              leadSerialNumber: d?.lead_serial_number,
              contactPerson: d?.contact_person,
              email: d?.email,
              organizationName: d?.organization_name,
              leadSource: d?.lead_source,
              serviceNeeded: d?.service_needed,
              valueOfLead: d?.value_of_lead,
            };
          })
          .sort((a, b) => b.points - a.points)
      );
    }
    if (reportType == "Leads by milestone level") {
      console.log("hurrr");
      setData(
        leads
          .map((d) => {
            return {
              milestone_level: d?.milestone_level,
              leadSerialNumber: d?.lead_serial_number,
              contactPerson: d?.contact_person,
              email: d?.email,
              organizationName: d?.organization_name,
              leadSource: d?.lead_source,
              serviceNeeded: d?.service_needed,
              valueOfLead: d?.value_of_lead,
            };
          })
          .sort((a, b) => b.points - a.points)
      );
    }
    if (reportType == "Leads by number of engagements/notes") {
      setData(
        leads
          .map((d) => {
            return {
              noOfNotes: d?.no_of_notes,
              leadSerialNumber: d?.lead_serial_number,
              contactPerson: d?.contact_person,
              email: d?.email,
              organizationName: d?.organization_name,
              leadSource: d?.lead_source,
              serviceNeeded: d?.service_needed,
              valueOfLead: d?.value_of_lead,
            };
          })
          .sort((a, b) => b.noOfNotes - a.noOfNotes)
      );
    }
    if (reportType == "Average action days") {
      setData(
        leads
          ?.map((lead) => {
            let startDate = new Date(lead?.date);
            let totalDays = 0;
            let totalHours = 0;
            let totalMinutes = 0;
            let endDate;

            if (lead?.end_date && (lead?.isDropped || lead?.Won)) {
              endDate = new Date(lead?.end_date);
            } else {
              endDate = new Date(Date.now());
            }
            console.log("start date", startDate);
            console.log("end date", endDate);
            let timeDifference = endDate.getTime() - startDate.getTime(); // Difference in milliseconds
            totalDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
            totalHours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24); // Convert milliseconds to hours and calculate the remaining hours
            totalMinutes = Math.floor((timeDifference / (1000 * 60)) % 60); // Convert milliseconds to minutes and calculate the remaining minutes
            let lastActionTime =
              totalDays +
              " days " +
              totalHours +
              " hours " +
              totalMinutes +
              "minutes";
            return {
              lastActionTime: lastActionTime,
              // lastActionTimeInMilliSeconds: timeDifference,
              leadSerialNumber: lead?.lead_serial_number,
              contactPerson: lead?.contact_person,
              email: lead?.email,
              organizationName: lead?.organization_name,
              leadSource: lead?.lead_source,
              serviceNeeded: lead?.service_needed,
              valueOfLead: lead?.value_of_lead,
            };
          })
          .sort(
            (a, b) =>
              a.lastActionTimeInMilliSeconds - b.lastActionTimeInMilliSeconds
          )
        // Remove 'lastActionTimeInMilliSeconds' key
      );
    }
    if (reportType == "Average number of engagements/notes") {
      api
        .get("/getAllUsersByAdmin")
        .then((res) => {
          console.log("response from getting users", res?.data);
          let allUsers =
            res?.data?.length < 1
              ? []
              : res?.data?.filter((r) => !r?.isBlocked);
          allUsers.push(user);
          console.log("all users in an organization", allUsers);
          Promise.all(
            allUsers?.map(async (u) => {
              try {
                let notes = await api.get(
                  `/notesOfUser/${u?.id}/${u?.organization_id}`
                );
                console.log("response of user notes", notes?.data);
                return {
                  name: u?.name,
                  email: u?.email_address,
                  profileType: u?.profile_type == "admin" ? "Admin" : "Partner",
                  notesCount: notes?.data?.length,
                };
              } catch (err) {
                console.error("Error fetching user notes:", err);
                return {
                  name: u?.name,
                  profileType: u?.profile_type == "admin" ? "Admin" : "Partner",
                  email: u?.email,
                  notesCount: [],
                };
              }
            })
          )
            .then((values) => {
              console.log("Resolved values:", values);
              setData(values);
            })
            .catch((err) => {
              console.error("Error resolving promises:", err);
            });
        })
        .catch((err) => {
          console.error("Error fetching all users in organization:", err);
        });
    }
    if (reportType == "List of won leads") {
      let wLeads = leads?.filter((l) => l?.is_won);
      setData(
        wLeads?.map((lead) => {
          return {
            leadSerialNumber: lead?.lead_serial_number,
            contactPerson: lead?.contact_person,
            email: lead?.email,
            endDate: new Date(lead?.end_date)?.toDateString(),
            organizationName: lead?.organization_name,
            leadSource: lead?.lead_source,
            serviceNeeded: lead?.service_needed,
            valueOfLead: lead?.value_of_lead,
          };
        })
      );
    }
    if (reportType == "List of dropped leads") {
      let dLeads = leads?.filter((l) => l?.is_won);
      setData(
        dLeads?.map((lead) => {
          return {
            leadSerialNumber: lead?.lead_serial_number,
            contactPerson: lead?.contact_person,
            email: lead?.email,
            endDate: new Date(lead?.end_date)?.toDateString(),
            organizationName: lead?.organization_name,
            leadSource: lead?.lead_source,
            serviceNeeded: lead?.service_needed,
            valueOfLead: lead?.value_of_lead,
          };
        })
      );
    }

    if (reportType == "Performance tracking week on week") {
      const today = new Date();
      const todayCopy = new Date(today);
      const currentWeekStart = new Date(
        todayCopy.setDate(today.getDate() - today.getDay())
      ); // Start of the current week
      const currentWeekEnd = new Date(
        todayCopy.setDate(currentWeekStart.getDate() + 6)
      ); // End of the current week

      // Create a new copy for calculating the previous week
      const todayCopyForPreviousWeek = new Date(today);
      const previousWeekStart = new Date(
        todayCopyForPreviousWeek.setDate(currentWeekStart.getDate() - 7)
      ); // Start of the previous week
      const previousWeekEnd = new Date(
        todayCopyForPreviousWeek.setDate(previousWeekStart.getDate() + 6)
      ); // End of the previous week

      console.log("Current week start", currentWeekStart?.toLocaleDateString());
      console.log("Current week end", currentWeekEnd?.toLocaleDateString());
      console.log(
        "Previous week start",
        previousWeekStart?.toLocaleDateString()
      );
      console.log("Previous week end", previousWeekEnd?.toLocaleDateString());

      /*************************** */

      let obj1 = getPerformance(previousWeekStart, previousWeekEnd);
      let obj2 = getPerformance(currentWeekStart, currentWeekEnd);
      console.log("previous week object", obj1);
      console.log("current week object", obj2);

      let arr = [];
      arr.push(obj1);
      arr.push(obj2);
      setData(arr);
    }

    if (reportType == "Performance tracking month on month") {
      const today = new Date();

      // Calculate current month's start and end date
      const currentMonthStart = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      );
      const nextMonthStart = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        1
      );
      const currentMonthEnd = new Date(nextMonthStart - 1);

      // Calculate previous month's start and end date
      const previousMonthStart = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      );
      const currentMonthFirst = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      );
      const previousMonthEnd = new Date(currentMonthFirst - 1);

      console.log(
        "Current month start",
        currentMonthStart?.toLocaleDateString()
      );
      console.log("Current month end", currentMonthEnd?.toLocaleDateString());
      console.log(
        "Previous month start",
        previousMonthStart?.toLocaleDateString()
      );
      console.log("Previous month end", previousMonthEnd?.toLocaleDateString());
      /*************************** */

      let obj1 = getPerformance(previousMonthStart, previousMonthEnd);
      let obj2 = getPerformance(currentMonthStart, currentMonthEnd);

      let arr = [];
      arr.push(obj1);
      arr.push(obj2);
      setData(arr);
    }
    if (reportType === "Performance tracking this year vs last year") {
      const today = new Date();
      const currentYear = today.getFullYear();

      // Calculate current year's start and end date
      const currentYearStart = new Date(currentYear, 0, 1);
      const currentYearEnd = new Date(currentYear, 11, 31);

      // Calculate previous year's start and end date
      const previousYearStart = new Date(currentYear - 1, 0, 1);
      const previousYearEnd = new Date(currentYear - 1, 11, 31);

      console.log("Current year start", currentYearStart?.toLocaleDateString());
      console.log("Current year end", currentYearEnd?.toLocaleDateString());
      console.log(
        "Previous year start",
        previousYearStart?.toLocaleDateString()
      );
      console.log("Previous year end", previousYearEnd?.toLocaleDateString());
      /*************************** */

      let obj1 = getPerformance(previousYearStart, previousYearEnd);
      let obj2 = getPerformance(currentYearStart, currentYearEnd);

      let arr = [];
      arr.push(obj1);
      arr.push(obj2);
      setData(arr);
    }
    if (
      reportType === "Performance tracking this month vs last year same month"
    ) {
      const today = new Date();
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();

      // Calculate current month's start and end date
      const currentMonthStart = new Date(currentYear, currentMonth, 1);
      const nextMonthStart = new Date(currentYear, currentMonth + 1, 1);
      const currentMonthEnd = new Date(nextMonthStart - 1);

      // Calculate same month from last year's start and end date
      const lastYearStart = new Date(currentYear - 1, currentMonth, 1);
      const lastYearNextMonthStart = new Date(
        currentYear - 1,
        currentMonth + 1,
        1
      );
      const lastYearEnd = new Date(lastYearNextMonthStart - 1);

      console.log(
        "Current month start",
        currentMonthStart?.toLocaleDateString()
      );
      console.log("Current month end", currentMonthEnd?.toLocaleDateString());
      console.log(
        "Last year same month start",
        lastYearStart?.toLocaleDateString()
      );
      console.log(
        "Last year same month end",
        lastYearEnd?.toLocaleDateString()
      );
      /*************************** */

      let obj1 = getPerformance(lastYearStart, lastYearEnd);
      let obj2 = getPerformance(currentMonthStart, currentMonthEnd);

      let arr = [];
      arr.push(obj1);
      arr.push(obj2);
      setData(arr);
    }
    if (
      reportType === "Performance tracking this week vs last year same week"
    ) {
      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth();

      const currentMonthStart = new Date(currentYear, currentMonth, 1);
      const currentMonthEnd = new Date(currentYear, currentMonth + 1, 0);

      const previousYearStart = new Date(currentYear - 1, currentMonth, 1);
      const previousYearEnd = new Date(currentYear - 1, currentMonth + 1, 0);

      let currentWeekStart = new Date(today);
      currentWeekStart.setDate(
        currentWeekStart.getDate() - currentWeekStart.getDay()
      );
      let currentWeekEnd = new Date(currentWeekStart);
      currentWeekEnd.setDate(currentWeekEnd.getDate() + 6);

      let previousYearWeekStart = new Date(previousYearStart);
      previousYearWeekStart.setDate(
        previousYearWeekStart.getDate() +
          currentWeekStart.getDate() -
          currentMonthStart.getDate()
      );
      let previousYearWeekEnd = new Date(previousYearWeekStart);
      previousYearWeekEnd.setDate(previousYearWeekEnd.getDate() + 6);

      console.log("Current week start", currentWeekStart?.toLocaleDateString());
      console.log("Current week end", currentWeekEnd?.toLocaleDateString());
      console.log(
        "Previous year same week start",
        previousYearWeekStart?.toLocaleDateString()
      );
      console.log(
        "Previous year same week end",
        previousYearWeekEnd?.toLocaleDateString()
      );
      /*************************** */

      let obj1 = getPerformance(previousYearWeekStart, previousYearWeekEnd);
      let obj2 = getPerformance(currentWeekStart, currentWeekEnd);

      let arr = [];
      arr.push(obj1);
      arr.push(obj2);
      setData(arr);
    }
    /************************** */
    if (reportType == "Performance tracking vs target") {
      api
        .get("/getAllUsersByAdmin")
        .then((res) => {
          console.log("response from getting users", res?.data);
          console.log("user her", user);

          let users = res?.data;
          // users?.push(user);
          console.log("users after pushing", users);

          Promise.all(
            users?.map(async (u) => {
              try {
                let target = await api.get("/target/" + u?.id);
                return {
                  id: u?.id,
                  name: u?.name,
                  email: u?.email_address,
                  dailyTarget: target?.data.daily_target,
                  targetStartDate: target?.data.start_date,
                  targetEndDate: target?.data.end_date,
                };
              } catch (error) {
                // Handle the error if necessary
                console.error("Error fetching data:", error);
                return {
                  id: u?.id,
                  name: u?.name,
                  email: u?.email_address,
                  targetNotSet: true,
                  dailyTarget: "N/A",
                  targetStartDate: "",
                  targetEndDate: "",
                };
              }
            })
          ).then((usersTargetData) => {
            console.log("usersTargetData", usersTargetData);

            let finalData = usersTargetData?.map((u) => {
              let performanceObject = getPerformance(
                new Date(u?.targetStartDate),
                new Date(u?.targetEndDate)
              );
              if (u?.targetNotSet) {
                return {
                  partner: `${u?.name} (${u?.email})`,
                  startDate: "target not set",
                };
              }
              console.log(
                "performance object for user" + u?.name,
                performanceObject
              );
              console.log(
                "orgaization information in main",
                organizationInformation
              );
              // return {
              //   partner: `${u?.name} (${u?.email})`,
              //   startDate: performanceObject?.Starting,
              //   endDate: performanceObject?.Ending,
              //   dailyTarget: `${u?.dailyTarget} (${organizationInformation?.organization_currency})`,
              //   totalContacts: performanceObject?.totalContacts,
              //   newContacts: performanceObject?.newContacts,
              //   closedLeads: performanceObject?.closedLeads,
              //   totalLeadsInProgress: performanceObject?.totalLeadsInProgress,
              //   leadsWon: performanceObject?.leadsWon,
              //   leadsLost: performanceObject?.leadsLost,
              //   dealConversionRate: performanceObject?.dealConversionRate,
              //   toatlRevenue: performanceObject?.toatlRevenue,
              //   averageDealSize: performanceObject?.averageDealSize,
              //   leadsGenerated: performanceObject?.leadsGenerated,
              // };
              // Assuming performanceObject, u, and organizationInformation are defined
              const startDate = new Date(performanceObject?.Starting);
              const endDate = new Date(performanceObject?.Ending);

              // Calculate the number of days between start and end date
              const timeDiff = Math.abs(endDate - startDate);
              const totalDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

              // Calculate the total target by multiplying daily target with total days
              const totalTarget = u?.dailyTarget * totalDays;

              // Now, construct the result object with the calculated total target
              return {
                partner: `${u?.name} (${u?.email})`,
                startDate: performanceObject?.Starting,
                endDate: performanceObject?.Ending,
                Target: `${totalTarget} (${organizationInformation?.organization_currency})`,
                totalContacts: performanceObject?.totalContacts,
                newContacts: performanceObject?.newContacts,
                closedLeads: performanceObject?.closedLeads,
                totalLeadsInProgress: performanceObject?.totalLeadsInProgress,
                leadsWon: performanceObject?.leadsWon,
                leadsLost: performanceObject?.leadsLost,
                dealConversionRate: performanceObject?.dealConversionRate,
                totalRevenue: performanceObject?.totalRevenue,
                averageDealSize: performanceObject?.averageDealSize,
                leadsGenerated: performanceObject?.leadsGenerated,
              };
            });
            console.log("final data", finalData);
            setData(finalData);
          });
        })
        .catch((err) => console.log("err", err));
    }
  }, [leads, reportType, organizationInformation]);
  const handleCompare = ({ input1, input2 }) => {
    let comparisonData = [];

    if (reportType === "Performance tracking week on week") {
      const week1Start = new Date(input1);
      const week1End = new Date(week1Start);
      week1End.setDate(week1Start.getDate() + 6);

      const week2Start = new Date(input2);
      const week2End = new Date(week2Start);
      week2End.setDate(week2Start.getDate() + 6);

      comparisonData = [
        getPerformance(week1Start, week1End),
        getPerformance(week2Start, week2End),
      ];
    }

    if (reportType === "Performance tracking month on month") {
      const month1Start = new Date(input1);
      const month1End = new Date(
        month1Start.getFullYear(),
        month1Start.getMonth() + 1,
        0
      );

      const month2Start = new Date(input2);
      const month2End = new Date(
        month2Start.getFullYear(),
        month2Start.getMonth() + 1,
        0
      );

      comparisonData = [
        getPerformance(month1Start, month1End),
        getPerformance(month2Start, month2End),
      ];
    }

    if (reportType === "Performance tracking this year vs last year") {
      const year = parseInt(input1);
      const currentYearStart = new Date(year, 0, 1);
      const currentYearEnd = new Date(year, 11, 31);

      const lastYearStart = new Date(year - 1, 0, 1);
      const lastYearEnd = new Date(year - 1, 11, 31);

      comparisonData = [
        getPerformance(lastYearStart, lastYearEnd),
        getPerformance(currentYearStart, currentYearEnd),
      ];
    }

    if (
      reportType === "Performance tracking this month vs last year same month"
    ) {
      const currentMonthStart = new Date(input1);
      const lastYearMonthStart = new Date(
        currentMonthStart.getFullYear() - 1,
        currentMonthStart.getMonth(),
        1
      );

      const currentMonthEnd = new Date(
        currentMonthStart.getFullYear(),
        currentMonthStart.getMonth() + 1,
        0
      );
      const lastYearMonthEnd = new Date(
        lastYearMonthStart.getFullYear(),
        lastYearMonthStart.getMonth() + 1,
        0
      );

      comparisonData = [
        getPerformance(lastYearMonthStart, lastYearMonthEnd),
        getPerformance(currentMonthStart, currentMonthEnd),
      ];
    }

    if (
      reportType === "Performance tracking this week vs last year same week"
    ) {
      const currentWeekStart = new Date(input1);
      const currentWeekEnd = new Date(currentWeekStart);
      currentWeekEnd.setDate(currentWeekStart.getDate() + 6);

      const lastYearWeekStart = new Date(currentWeekStart);
      lastYearWeekStart.setFullYear(lastYearWeekStart.getFullYear() - 1);

      const lastYearWeekEnd = new Date(lastYearWeekStart);
      lastYearWeekEnd.setDate(lastYearWeekStart.getDate() + 6);

      comparisonData = [
        getPerformance(lastYearWeekStart, lastYearWeekEnd),
        getPerformance(currentWeekStart, currentWeekEnd),
      ];
    }

    if (reportType === "Performance tracking vs target") {
      const customStartDate = new Date(input1);
      const customEndDate = new Date(input2);

      api
        .get("/getAllUsersByAdmin")
        .then((res) => {
          console.log("response from getting users", res?.data);
          console.log("user her", user);

          let users = res?.data;

          // Fetch targets and calculate performance for each user
          Promise.all(
            users?.map(async (u) => {
              try {
                let target = await api.get("/target/" + u?.id);
                return {
                  id: u?.id,
                  name: u?.name,
                  email: u?.email_address,
                  dailyTarget: target?.data.daily_target,
                  targetStartDate: target?.data.start_date,
                  targetEndDate: target?.data.end_date,
                };
              } catch (error) {
                console.error("Error fetching data:", error);
                return {
                  id: u?.id,
                  name: u?.name,
                  email: u?.email_address,
                  targetNotSet: true,
                  dailyTarget: "N/A",
                  targetStartDate: "",
                  targetEndDate: "",
                };
              }
            })
          ).then((usersTargetData) => {
            console.log("usersTargetData", usersTargetData);

            let finalData = usersTargetData?.map((u) => {
              // Use custom date range from modal, fallback to the user's target range if not set
              const startDate = customStartDate
                ? new Date(customStartDate)
                : new Date(u?.targetStartDate);
              const endDate = customEndDate
                ? new Date(customEndDate)
                : new Date(u?.targetEndDate);

              // Calculate performance for the selected date range
              let performanceObject = getPerformance(startDate, endDate);

              if (u?.targetNotSet) {
                return {
                  partner: `${u?.name} (${u?.email})`,
                  startDate: "Target not set",
                };
              }

              console.log(
                "performance object for user" + u?.name,
                performanceObject
              );
              console.log(
                "organization information in main",
                organizationInformation
              );

              // Calculate total target based on daily target and date range
              const timeDiff = Math.abs(endDate - startDate);
              const totalDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
              const totalTarget = u?.dailyTarget * totalDays;

              // Construct result object
              return {
                partner: `${u?.name} (${u?.email})`,
                startDate: performanceObject?.Starting,
                endDate: performanceObject?.Ending,
                Target: `${totalTarget} (${organizationInformation?.organization_currency})`,
                totalContacts: performanceObject?.totalContacts,
                newContacts: performanceObject?.newContacts,
                closedLeads: performanceObject?.closedLeads,
                totalLeadsInProgress: performanceObject?.totalLeadsInProgress,
                leadsWon: performanceObject?.leadsWon,
                leadsLost: performanceObject?.leadsLost,
                dealConversionRate: performanceObject?.dealConversionRate,
                totalRevenue: performanceObject?.totalRevenue,
                averageDealSize: performanceObject?.averageDealSize,
                leadsGenerated: performanceObject?.leadsGenerated,
              };
            });

            console.log("final data", finalData);
            setData(finalData);
          });
        })
        .catch((err) => console.log("err", err));
    }

    setData(comparisonData);
  };
  const getPerformance = (startDate, endDate) => {
    console.log(startDate, endDate);
    let start = new Date(startDate);
    let end = new Date(endDate);
    console.log("start at get performance", start);
    console.log("end at get performance", start);
    console?.log("leads", leads);
    /******************** */
    let total_contacts = leads;
    /************** */
    let new_contacts_added_this_week = [];
    new_contacts_added_this_week = leads?.filter((l) => {
      if (new Date(l?.date) >= start && new Date(l?.date) <= end) {
        return true;
      }
      return false;
    });
    /******************* */
    let closed_leads_this_week = [];
    closed_leads_this_week = leads?.filter((l) => {
      if (new Date(l?.end_date) >= start && new Date(l?.end_date) <= end) {
        return true;
      }
      return false;
    });
    /*********************************** */
    let total_leads_in_progress = leads?.filter((l) => {
      if (l?.is_dropped || l?.is_won)
        if (new Date(l?.end_date) > end && new Date(l?.date) <= end)
          return true;
        else return false;

      if (new Date(l?.date) <= end) return true;

      return false;
    });
    /****************************** */
    let leads_won_this_week = closed_leads_this_week?.filter((l) => {
      if (l?.is_won) return true;
      return false;
    });
    let leads_lost_this_week = closed_leads_this_week?.filter((l) => {
      if (l?.is_dropped) return true;
      return false;
    });
    /************** */
    console.log(
      "leads_won_this_week?.length / total_leads_in_progress?.length",
      leads_won_this_week?.length,
      total_leads_in_progress?.length + closed_leads_this_week?.length
    );
    let deal_conversion_rate =
      (leads_won_this_week?.length /
        (total_leads_in_progress?.length + closed_leads_this_week?.length)) *
      100;
    deal_conversion_rate =
      deal_conversion_rate >= 0 ? `${deal_conversion_rate?.toFixed(2)}%` : 0;

    /*********** */
    let total_revenue = 0;
    leads_won_this_week?.forEach(
      (l) =>
        (total_revenue =
          parseFloat(total_revenue) + parseFloat(l?.value_of_lead))
    );
    const total_revenue_string =
      total_revenue >= 0
        ? `${total_revenue?.toFixed(2)} ${
            organizationInformation?.organization_currency
          }`
        : 0;
    /*********** */
    let average_deal_size = 0;
    if (total_revenue) {
      average_deal_size = total_revenue / leads_won_this_week?.length;
    }
    const average_deal_size_string =
      average_deal_size >= 0
        ? `${average_deal_size?.toFixed(2)} ${
            organizationInformation?.organization_currency
          }`
        : 0;
    /************* */
    let leads_generated = leads?.filter((l) => {
      if (new Date(l?.date) >= start && new Date(l?.date) <= end) {
        return true;
      }
      return false;
    });
    /****************** */
    return {
      Starting: new Date(startDate)?.toDateString(),
      Ending: new Date(endDate)?.toDateString(),
      totalContacts: total_contacts?.length,
      newContacts: new_contacts_added_this_week?.length,
      closedLeads: closed_leads_this_week?.length,
      totalLeadsInProgress: total_leads_in_progress?.length,
      leadsWon: leads_won_this_week?.length,
      leadsLost: leads_lost_this_week?.length,
      dealConversionRate: deal_conversion_rate,
      totalRevenue: total_revenue_string,
      averageDealSize: average_deal_size_string,
      leadsGenerated: leads_generated?.length,
    };
  };

  useEffect(() => {
    console.log("data", data);
    setAverageActionDays("");
    if (reportType == "Average action days") {
      let averageTime = 0;
      if (data?.length > 0 && data[0]?.lastActionTimeInMilliSeconds)
        data?.forEach((l) => {
          averageTime = averageTime + l?.lastActionTimeInMilliSeconds;
        });
      averageTime = averageTime / data?.length;
      let totalDays = Math.floor(averageTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
      let totalHours = Math.floor((averageTime / (1000 * 60 * 60)) % 24); // Convert milliseconds to hours and calculate the remaining hours
      let totalMinutes = Math.floor((averageTime / (1000 * 60)) % 60); // Convert milliseconds to minutes and calculate the remaining minutes
      let averageLastActionTime =
        totalDays +
        " days " +
        totalHours +
        " hours " +
        totalMinutes +
        " minutes";
      setAverageActionDays(averageLastActionTime);
    }
    if (reportType == "Average number of engagements/notes") {
      let average = 0;
      if (data?.length > 0) {
        data?.forEach((d) => {
          average = average + d?.notesCount;
        });
        average = parseInt(average / data?.length);
      }
      setAverageNoOfNotes(average);
    }
  }, [data]);

  const getNormalCase = (str) => {
    return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  };
  const uploadFileToBackend = async (file, isJSON) => {
    const formData = new FormData();
    isJSON
      ? formData.append(
          "files",
          new Blob([file], { type: "application/json" }),
          "data.json"
        )
      : formData.append("files", file);

    try {
      const response = await api.post("/files/upload/space", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("url", response?.data?.url);
      return response.data.url; // Assuming the response contains the S3 URL
    } catch (error) {
      // console.error(error);
      setIsLoading(false);
      // toast.error("unable to cntinue at the moment!");
      return null;
    }
  };
  const downloadReport = async () => {
    setIsLoading(true);
    const fileName = `${reportType.replaceAll(" ", "_")}_Report.xlsx`;

    // Create a new workbook and add a worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Adding average action days as an additional row
    if (averageActionDays && reportType === "Average action days") {
      XLSX.utils.sheet_add_aoa(
        ws,
        [[`Average action days: ${averageActionDays}`]], // adding a 2D array for a single row
        { origin: -1 } // placing the new row at the end
      );
    }
    if (
      averageNoOfNotes &&
      reportType === "Average number of engagements/notes"
    ) {
      XLSX.utils.sheet_add_aoa(
        ws,
        [[`Average No Of Notes/engagements: ${averageNoOfNotes}`]], // adding a 2D array for a single row
        { origin: -1 } // placing the new row at the end
      );
    }

    // Generate an XLSX file
    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });

    // const client = filestack.init(process.env.REACT_APP_FILESTACK_API);
    const file = new File([wbout], fileName, {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    try {
      const url = await uploadFileToBackend(file);
      setIsLoading(false);
      setShowDownloadButton(true);
      setDownloadFileUrl(url);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
    // client
    //   .upload(file, {})
    //   .then((res) => {
    //     console.log("Uploaded successfully", res);
    //     // window.open(res.url, "_blank");

    //     setIsLoading(false);
    //     setShowDownloadButton(true);
    //     setDownloadFileUrl(res?.url);
    //   })
    //   .catch((error) => {
    //     console.error("Error uploading file: ", error);
    //     setIsLoading(false);
    //   });
  };

  return (
    <Box sx={{ mt: 2, background: "white", p: 2 }}>
      <Button
        size="small"
        variant="outlined"
        onClick={() => setShowReport(false)}
      >
        Back
      </Button>
      <Box sx={{ my: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: "600", color: theme.palette.primary.main, my: 2 }}
          >
            {reportType}
          </Typography>
          {isLoading ? (
            <Typography>Processing...</Typography>
          ) : (
            <Box>
              {showDownloadButton ? (
                <Box sx={{ display: "flex", gap: 2 }}>
                  {[
                    "Performance tracking week on week",
                    "Performance tracking month on month",
                    "Performance tracking this year vs last year",
                    "Performance tracking this month vs last year same month",
                    "Performance tracking this week vs last year same week",
                    "Performance tracking vs target",
                  ]?.includes(reportType) && (
                    <PerformanceComparisonModal
                      onCompare={handleCompare}
                      performanceReportType={reportType}
                    />
                  )}

                  <a href={downloadFileUrl} target="_blank">
                    <Typography>View File</Typography>
                  </a>
                  <Button
                    size="small"
                    sx={{ ml: 2 }}
                    onClick={() => setShowDownloadButton(false)}
                    variant="contained"
                  >
                    Download again
                  </Button>
                </Box>
              ) : (
                <Box sx={{ display: "flex", gap: 2 }}>
                  {[
                    "Performance tracking week on week",
                    "Performance tracking month on month",
                    "Performance tracking this year vs last year",
                    "Performance tracking this month vs last year same month",
                    "Performance tracking this week vs last year same week",
                    "Performance tracking vs target",
                  ]?.includes(reportType) && (
                    <PerformanceComparisonModal
                      onCompare={handleCompare}
                      performanceReportType={reportType}
                    />
                  )}
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => downloadReport()}
                  >
                    Download Report
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {Object.keys(data[0] || {}).map((key, index) => (
                <TableCell sx={{ whiteSpace: "nowrap" }} key={index}>
                  {getNormalCase(key)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.map(({ lastActionTimeInMilliSeconds, ...rest }) => rest)
              ?.map((row, index) => (
                <TableRow key={index}>
                  {Object.keys(row).map((key, index) => (
                    <TableCell sx={{ whiteSpace: "nowrap" }} key={index}>
                      {row[key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {averageActionDays && reportType == "Average action days" && (
        <Box sx={{ my: 2 }}>
          <Typography sx={{ color: theme.palette.primary.main }}>
            Average action days:
          </Typography>
          <Typography>{averageActionDays}</Typography>
        </Box>
      )}
      {averageNoOfNotes &&
        reportType == "Average number of engagements/notes" && (
          <Box sx={{ my: 2 }}>
            <Typography sx={{ color: theme.palette.primary.main }}>
              Average No. Of Notes:
            </Typography>
            <Typography>{averageNoOfNotes}</Typography>
          </Box>
        )}
    </Box>
  );
};

export default ViewReports;
