import React, { useState, useEffect } from "react";

import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  useMediaQuery,
  RadioGroup,
  Radio,
  FormControlLabel,
  Input,
  Switch,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { api } from "../../../axios/api";
import { ToastContainer, toast } from "react-toastify";
import styled from "@mui/material/styles/styled";
import { Icon } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { Card, CardContent, makeStyles } from "@mui/material";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import AuthPhoneInputMpesa from "../../InputFields/AuthPhoneInputMpesa";

const SubscriptionOption = ({
  title,
  description,
  price,
  monthly_price,
  users,
  leads,
  features,
  onSelect,
  isSubscribed,
  activeMonthlyBilling,
}) => {
  const theme = useTheme();
  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();

  function fetchUserSubscriptionData() {
    api
      .get("/user/subscription")
      .then((res) => {
        console.log("response from getting user subscriptio", res.data);
        dispatch({
          type: "SET_Subscription_Data",
          payload: res?.data,
        });
      })
      .catch((err) => console.log("err from getting user", err));
  }

  const cancelSubscription = () => {
    api
      .put("/users/cancel-subscription")
      .then((res) => {
        console.log("response", res?.data);
        toast.success("subscription cancelled!");
        fetchUserSubscriptionData();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("unable to cancel subscription!");
      });
  };

  return (
    <Card
      sx={{
        "&:hover": {
          boxShadow:
            !isSubscribed &&
            title != "Free for life" &&
            "0 0 10px rgba(0, 0, 0, 0.2)",
          backgroundColor:
            !isSubscribed &&
            title != "Free for life" &&
            theme.palette.selectedMenuBack.main,
        },
        margin: "16px 10px",
        padding: { xs: "10px", lg: "16px" },
        transition: "box-shadow 0.3s",
        cursor: !isSubscribed && title != "Free for life" && "pointer",
        background: isSubscribed && theme.palette.selectedMenuBack?.main,
      }}
      onClick={() => !isSubscribed && title != "Free for life" && onSelect()}
    >
      {/* <ToastContainer /> */}
      <CardContent>
        <Box
          sx={{
            minHeight: "65px",
            my: 2,
            // border: "3px solid green"
          }}
        >
          <Typography
            variant="h6"
            component="Box"
            sx={{
              color: theme.palette.heading.main,
              fontWeight: "600",
              fontSize: "1.1rem",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "400",
              fontSize: "0.8rem",
              color: theme.palette.para.main,
              mt: "-0.1rem",
              lineHeight: "15px",
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // border: "2px solid red",
          }}
        >
          {isSubscribed ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontSize: "1.6rem",
                  fontWeight: "600",
                  color: theme.palette.primary.main,
                }}
              >
                Subscribed
              </Typography>
              {/* {title != "Free for life" && (
                <Button onClick={() => cancelSubscription()}>Cancel</Button>
              )} */}
            </Box>
          ) : (
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                fontSize: "1.6rem",
                fontWeight: "600",
              }}
            >
              Ksh {activeMonthlyBilling ? monthly_price : price}
              <sub style={{ fontSize: "1rem" }}>
                /{activeMonthlyBilling ? "Month" : "Year"}
              </sub>
            </Typography>
          )}
        </Box>

        {/* <Typography color="textSecondary">{users} users</Typography>
        <Typography color="textSecondary">{leads} leads</Typography> */}
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: "10px", mt: 2 }}
        >
          {features.map((feature, index) => (
            <Box key={index} sx={{ display: "flex" }}>
              <Box
                sx={{
                  marginRight: "11px",
                  color: theme.palette.primary.main,
                  fontWeight: "600",
                }}
              >
                ✓
              </Box>
              <Typography variant="p" sx={{ fontSize: "0.9rem" }}>
                {feature}
              </Typography>
            </Box>
          ))}
        </Box>
        {/* <Button variant="contained" color="primary">
          Subscribe
        </Button> */}
      </CardContent>
    </Card>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Pricing = () => {
  const theme = useTheme();
  const [numUsers, setNumUsers] = useState(0);
  const [billingType, setBillingType] = useState("monthly");
  const [selected, setSelected] = useState("free");
  const [billingAmount, setBillingAmount] = useState(0);
  const user = useSelector((state) => state?.user);
  const userSubscriptionData = useSelector((state) => state?.subscription_data);
  const [activeMonthlyBilling, setActiveMonthlyBilling] = useState(false);
  const dispatch = useDispatch();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  let intervalId;
  useEffect(() => {
    console.log("user", user);
    // setPhoneNumber(user?.phone_number);
  }, [user]);

  /********phone */
  const validateMpesaPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\+?[0-9]{12,}$/; // matches phone numbers with at least 12 digits

    if (!phoneNumber) {
      setPhoneNumberError("Phone Number is required");
      return false;
    }
    if (!phoneRegex.test(phoneNumber)) {
      setPhoneNumberError("Enter valid phone number");
      return false;
    }
    if (!phoneNumber.startsWith("254")) {
      setPhoneNumberError("Enter a valid kenyan phone number 254...");
      return false;
    }
    setPhoneNumberError("");
    return true;
  };
  const handlePhoneNumberChange = (event) => {
    console.log("event", event);
    setPhoneNumber(event.target.value);
    setPhoneNumberError("");
  };

  const handleNumUsersChange = (event) => {
    setNumUsers(event.target.value);
    if (event.target.value >= 1) {
      setSelected("premium");
    } else {
      setSelected("free");
    }
  };

  const handleBillingTypeChange = (event) => {
    setBillingType(event.target.value);
  };

  useEffect(() => {
    console.log("billing Amount Updated", billingAmount);
  }, [billingAmount]);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    console.log("selected option>>>>", option);
    handleOpen();
  };
  const [openPaymentModal, setOpenPaymentModal] = React.useState(false);
  const handleOpen = () => setOpenPaymentModal(true);
  const handleClose = () => {
    setCouponCode("");
    setIsCouponApplied(false);
    setTotalOrderValue(0);
    setOpenPaymentModal(false);
  };
  const [totalOrderValue, setTotalOrderValue] = useState(0);
  useEffect(() => {
    if (selectedOption?.price) {
      activeMonthlyBilling
        ? setTotalOrderValue(selectedOption?.monthly_price)
        : setTotalOrderValue(selectedOption?.price);
    }
  }, [selectedOption]);
  const [couponCode, setCouponCode] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponError, setCouponError] = useState("");
  const useCouponCode = () => {
    if (!couponCode) {
      setCouponError("missing field");
      return;
    }
    api
      .put("/coupons/use", { code: couponCode })
      .then((res) => {
        console.log("res", res?.data);
        let c = res?.data;
        console.log("totalOrderValue ", totalOrderValue);
        console.log(" parseInt(c?.value)", parseInt(c?.value));
        console.log(
          "totalOrderValue - parseInt(c?.value)",
          totalOrderValue - parseInt(c?.value)
        );
        setTotalOrderValue(totalOrderValue - parseInt(c?.value));
        setIsCouponApplied(true);
        toast.success("coupon applied!");
      })
      .catch((err) => {
        console.log(err);
        setCouponError("unable to apply this coupon");
      });
  };
  useEffect(() => {
    setCouponError("");
  }, [couponCode]);

  // useEffect(() => {
  //   console.log("running useefeect", numUsers, billingType, billingAmount);
  //   if (numUsers >= 1) {
  //     console.log("inner");
  //     if (numUsers == 1) {
  //       if (billingType == "monthly") {
  //         setBillingAmount(20);
  //       }
  //       if (billingType == "semiAnnual") {
  //         setBillingAmount(90);
  //       }
  //       if (billingType == "annual") {
  //         setBillingAmount(180);
  //       }
  //     }
  //     if (numUsers > 1 && numUsers <= 5) {
  //       if (billingType == "monthly") {
  //         console.log("monthly", 30);
  //         setBillingAmount(30);
  //       }
  //       if (billingType == "semiAnnual") {
  //         setBillingAmount(120);
  //       }
  //       if (billingType == "annual") {
  //         setBillingAmount(210);
  //       }
  //     }
  //     if (numUsers > 5 && numUsers <= 20) {
  //       if (billingType == "monthly") {
  //         setBillingAmount(50);
  //       }
  //       if (billingType == "semiAnnual") {
  //         setBillingAmount(250);
  //       }
  //       if (billingType == "annual") {
  //         setBillingAmount(400);
  //       }
  //     }
  //     if (numUsers > 20) {
  //       setBillingAmount(0);
  //     }
  //   } else {
  //     setBillingAmount(0);
  //   }
  // }, [numUsers, billingType]);
  // const subscriptionOptions = [
  //   {
  //     title: "Free for life",
  //     description: "Everyone is invited. Don't be left behind",
  //     price: 0,
  //     //   users: "2 users",
  //     //   leads: "10 leads",
  //     features: [
  //       "2 users",
  //       "10 leads",
  //       "Earn money/Post on the Notice Board",
  //       "View available opportunities",
  //       // "Manage leads pipeline",
  //     ],
  //   },
  //   {
  //     title: "Starter Pack",
  //     description: "Run your business with expertise.",
  //     price: 14400,
  //     //   users: "3 users",
  //     //   leads: "unlimited leads",
  //     features: [
  //       "3 users",
  //       "unlimited leads",
  //       "Earn money/Post on the Notice Board",
  //       "View available opportunities",
  //       "Manage leads pipeline",
  //       "View Leads Performance Reports",
  //     ],
  //   },
  //   {
  //     title: "Booster Pack",
  //     description: "Speed up your success. Grab a Booster.",
  //     price: 18300,
  //     //   users: "5 users",
  //     //   leads: "unlimited leads",
  //     features: [
  //       "5 users",
  //       "unlimited leads",
  //       "Earn money/Post on the Notice Board",
  //       "View available opportunities",
  //       "Manage leads pipeline",
  //       "View Leads Performance Reports",
  //       "View Premium Referrals",
  //     ],
  //   },
  //   {
  //     title: "Power Pack",
  //     description: "Amplify your success with added power",
  //     price: 25200,
  //     //   users: "10 users",
  //     //   leads: "unlimited leads",
  //     features: [
  //       "10 users",
  //       "unlimited leads",
  //       "Earn money/Post on the Notice Board",
  //       "View available opportunities",
  //       "Manage leads pipeline",
  //       "View Leads Performance Reports",
  //       "View Premium Referrals",
  //       "Create a Network",
  //       "Manage a Network",
  //     ],
  //   },
  //   // Add more subscription options as needed
  // ];
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  function fetchUserSubscriptionData() {
    api
      .get("/user/subscription")
      .then((res) => {
        console.log("response from getting user subscriptio", res.data);
        dispatch({
          type: "SET_Subscription_Data",
          payload: res?.data,
        });
      })
      .catch((err) => console.log("err from getting user", err));
  }
  useEffect(() => {
    api
      .get("/subscriptions/general")
      .then((res) => {
        console.log("subscriptions", res?.data);
        setSubscriptionOptions(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const [subscribedOption, setSubscribedOption] = useState({});
  useEffect(() => {
    if (userSubscriptionData?.offer_purchased) {
      setSubscribedOption(
        subscriptionOptions?.filter(
          (op) =>
            op?.title?.toLowerCase() ==
            userSubscriptionData?.offer_purchased?.toLowerCase()
        )[0]
      );
    } else setSubscribedOption(subscriptionOptions[0]);
  }, [subscriptionOptions, userSubscriptionData]);

  // Function to check for txn changes
  const checkForTxnChanges = async (mpesaRef) => {
    try {
      api
        .get("/get-mpesa-transaction/" + mpesaRef)
        .then((res) => {
          console.log("response from getting mpesa txn", res.data);
          const newStatus = res?.data?.status;
          console.log("txn check status", newStatus);
          if (newStatus == "Waiting User Action") {
            toast.success("Mpesa request sent. Please check your phone!", {
              autoClose: 3000,
            });
          } else if (newStatus == "Transaction Successful") {
            toast.success(
              "Transaction Successful. Account upgraded successfully!",
              {
                autoClose: 10000,
              }
            );
            clearInterval(intervalId);
            fetchUserSubscriptionData();
          } else {
            toast.error(newStatus, {
              autoClose: 10000,
            });
            clearInterval(intervalId);
            fetchUserSubscriptionData();
          }
        })
        .catch((err) => console.log("err from getting txn", err));
    } catch (error) {
      console.log("txn check error", error);
    }
  };

  const checkoutOffer = () => {
    const isPhoneValid = validateMpesaPhoneNumber(phoneNumber);
    if (isPhoneValid) {
      if (selectedOption?.title) {
        console.log(
          "subscription title at selectedOptions title",
          selectedOption?.title
        );
        api
          .put(`/users/purchase-subscription-mpesa/${user?.id}`, {
            title: selectedOption?.title,
            phoneNumber: phoneNumber,
            totalOrderValue: totalOrderValue,
          })
          .then((res) => {
            console.log("response from checkout", res?.data);
            // toast.success("Account upgraded successfully!");
            toast.success(
              "Mpesa request sent successfully ✔. Please check your phone!",
              {
                autoClose: 5000, // Set the duration in milliseconds (5 seconds)
              }
            );

            if (
              res?.data?.ref !== undefined &&
              res?.data?.ref !== null &&
              res?.data?.ref !== ""
            ) {
              console.log("txn check initiated", res?.data?.ref);
              intervalId = setInterval(() => {
                checkForTxnChanges(res?.data?.ref);
              }, 5000); // Check txn status every 5 seconds
            } else {
              console.log("could not initiate txn check", res?.data?.ref);
            }

            setOpenPaymentModal(false);
            fetchUserSubscriptionData();
          })
          .catch((err) => {
            console.log("error", err);
            toast.error("An error occurred while subscribing. " + err);
          });
      }
    } else {
      toast.error("Enter valid phone number!");
    }
  };
  return (
    <Box>
      <ToastContainer />
      <Container>
        <Box
          sx={{
            margin: "20px 0",
            background: theme.palette.background.main,
            borderRadius: "4px",
            padding: { xs: "10px", sm: "30px" },
          }}
        >
          <Box sx={{ marginBottom: "10px" }}>
            {" "}
            <Typography
              variant="body2"
              csx={{ color: theme.palette.heading.main }}
            >
              Current subscription plan
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.colortwo.main,
                fontWeight: "600",
                fontSize: "1.1rem",
              }}
            >
              {userSubscriptionData?.ontrial &&
              !userSubscriptionData?.trial_done
                ? "On Trial (Starter Pack)"
                : userSubscriptionData?.offer_purchased &&
                  !userSubscriptionData?.account_expired
                ? userSubscriptionData.offer_purchased
                : "free"}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: "10px" }}>
            {/* <Typography
              variant="body2"
              sx={{ color: theme.palette.heading.main }}
            >
              Users you can add
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.colortwo.main,
                fontWeight: "600",
                fontSize: "1.1rem",
              }}
            ></Typography> */}
          </Box>
          <Box sx={{ marginBottom: "10px" }}>
            <Typography
              variant="body2"
              csx={{ color: theme.palette.heading.main, marginBottom: "20px" }}
            >
              Your benefits are
            </Typography>

            <Box sx={{ marginTop: "10px" }}>
              {subscribedOption?.features?.map((item, index) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  {item && (
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "400",
                        fontSize: "0.9rem",
                        color: theme.palette.colortwo.main,
                        // color: item.success ? "success.main" : "error.main",
                      }}
                    >
                      {item}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
            {userSubscriptionData?.accounType == "free" && (
              <Box sx={{ marginBottom: "10px" }}>
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.heading.main }}
                >
                  Upgrade to premium to unlock all features
                </Typography>
                {/* <Button variant="contained" sx={{ marginTop: "20px" }}>
                Upgrade plan
              </Button> */}
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            minHeight: "300px",

            padding: "30px",
            background: theme.palette.background.main,
          }}
        >
          <Container>
            <Modal
              open={openPaymentModal}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ zIndex: "1000000000" }}
            >
              <Box sx={style}>
                <Box sx={{}}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">Order Summary</Typography>
                    <IconButton onClick={handleClose}>
                      <ClearIcon />
                    </IconButton>
                  </Box>

                  <Box sx={{ my: 1 }}>
                    <Typography variant="body2">Selected Option</Typography>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        color: theme.palette.primary.main,
                        fontSize: "1.2rem",
                      }}
                    >
                      {selectedOption?.title}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ my: 3, borderBottom: "1px solid lightgray", pb: 5 }}
                  >
                    {isCouponApplied ? (
                      <>
                        <Typography>Coupon Applied.</Typography>
                      </>
                    ) : (
                      <>
                        <Typography>Coupon Code</Typography>
                        <Box>
                          <Input
                            onChange={(e) => setCouponCode(e.target.value)}
                          />
                          <Button onClick={useCouponCode}>Apply</Button>
                          {couponError && (
                            <Typography sx={{ color: "red" }}>
                              {couponError}
                            </Typography>
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                  <Box
                    sx={{
                      my: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Total</Typography>
                    <Typography sx={{ fontWeight: "600", fontSize: "1.3rem" }}>
                      Ksh {totalOrderValue}
                    </Typography>
                  </Box>
                  <Typography>Mpesa Phone Number</Typography>
                  <Box sx={{ margin: "15px 0" }}>
                    <AuthPhoneInputMpesa
                      country={"ke"}
                      isPassword={false}
                      label="Phone Number"
                      placeholder="phone number"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      setPhoneNumber={setPhoneNumber}
                      error={phoneNumberError ? true : false}
                      helperText={phoneNumberError}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    sx={{ width: "100%", mt: 2 }}
                    onClick={() => checkoutOffer()}
                  >
                    Checkout
                  </Button>
                </Box>
              </Box>
            </Modal>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "700",
                color: theme.palette.heading.main,
                textAlign: "center",
              }}
            >
              Plans and Pricing
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                mt: 1,
                color: theme.palette.para.main,
              }}
            >
              Choose annual billing for big discount or go monthly for
              flexibility
            </Typography>

            <Box
            //   style={{
            //     display: "flex",
            //     justifyContent: "center",
            //     alignItems: "center",
            //     height: "100vh",
            //   }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 3,
                }}
              >
                {" "}
                <Typography>Annual</Typography>
                <Switch
                  value={activeMonthlyBilling}
                  onChange={() =>
                    setActiveMonthlyBilling(!activeMonthlyBilling)
                  }
                />
                <Typography>Monthly</Typography>
              </Box>
              <Box sx={{ my: 4 }}>
                <Box
                  style={{
                    display: "flex",
                    // flexWrap: "wrap"
                  }}
                >
                  <Grid container>
                    {subscriptionOptions.map((option, index) => (
                      <Grid xs={12} sm={6} md={4} lg={3} item>
                        <SubscriptionOption
                          key={index}
                          {...option}
                          onSelect={() => {
                            console.log("option>>>>>>>>>>>", option);
                            handleSelectOption(option);
                          }}
                          selected={selectedOption === option}
                          isSubscribed={
                            subscribedOption?.title == option?.title
                          }
                          activeMonthlyBilling={activeMonthlyBilling}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>{" "}
          {/* <Grid container gap={5.5}>
            <Grid item md={3.6} xs={12}>
             
              <Grid
                container
                justifyContent="center"
                // gap={xs:0,md:1}
                sx={{ marginTop: "70px" }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px 0",
                  }}
                >
                  <Typography variant="h6" color={theme.palette.primary.main}>
                    Benefits
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    // border: "1px solid lightgray",
                    borderColor: "lightgray",
                    marginTop: { xs: "30px", sm: "0" },
                  }}
                >
                  <Box
                    sx={{
                      // borderBottom: "1px solid lightgray",
                      borderColor: "lightgray",
                      // backgroundColor: "white",
                      backgroundColor: theme.palette.colorthree.main,
                      color: "black",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="h5"
                      sx={{
                        textAlign: "center",
                        fontWeight: "600",
                        padding: "20px",
                        color: theme.palette.primary.main,
                      }}
                    >
                      Premium Account
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      // borderBottom: "1px solid lightgray",
                      // borderColor:
                      //   selected == "premium"
                      //     ? theme.palette.primary.main
                      //     : "lightgray",
                      padding: "20px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      backgroundColor: theme.palette.colorthree.main,
                    }}
                  >
                    <Box sx={{}}>
                      {premiumData?.map((item, index) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          {item.success ? (
                            <Icon color="success">
                              <Check />
                            </Icon>
                          ) : (
                            <Icon color="error">
                              <Close />
                            </Icon>
                          )}
                          <Typography
                            variant="body2"
                            sx={{
                              ml: 1,
                              fontWeight: "400",
                              fontSize: "0.95rem",
                              color: theme.palette.heading.main,
                              // color: item.success ? "success.main" : "error.main",
                            }}
                          >
                            {item.text}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <CustomButton
                      disabled
                      sx={{
                        width: "max-content",
                        height: "40px",
                        fontSize: "0.8rem",
                        padding: "8px",
                        marginTop: "10px",
                        // marginLeft: "50%",
                        // transform: "translateX(-50%)",

                        backgroundColor: "transparent",
                        color: theme.palette.colortwo.main,
                        border: `none`,
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Check Premium Options
                    </CustomButton>
                  </Box>
                </Grid>
              </Grid>
             
            </Grid>
            <Grid
              item
              xs={12}
              md={7.8}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "9px 0",
              }}
            >
            
              <>
                {" "}
                <Grid
                  container
                  justifyContent="center"
                  // gap={xs:0,md:1}
                  sx={
                    {
                      // marginTop: "30px",
                      // maxWidth: { sm: "70%", xs: "100%" },
                    }
                  }
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "0px 0 20px 0",
                    }}
                  >
                    <Typography color={theme.palette.primary.main} variant="h6">
                      Premium Options
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      backgroundColor: theme.palette.primary.main,
                      color: "white",
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Users Allowed
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      backgroundColor: theme.palette.primary.main,
                      color: "white",
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Annual
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      backgroundColor: theme.palette.primary.main,
                      color: "white",
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Bi-Annual
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      backgroundColor: theme.palette.primary.main,
                      color: "white",
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Monthly
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      background: theme.palette.colorthree.main,
                      fontWeight: "600",
                      color: theme.palette.heading.main,
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    1 USER
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        backgroundColor: theme.palette.colorthree.main,
                        fontWeight: "600",
                      },
                      "&:active": {
                        backgroundColor: theme.palette.primary.main,
                        fontWeight: "600",
                        color: "white",
                      },
                    }}
                  >
                    $180
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        backgroundColor: theme.palette.colorthree.main,
                        fontWeight: "600",
                      },
                      "&:active": {
                        backgroundColor: theme.palette.primary.main,
                        fontWeight: "600",
                        color: "white",
                      },
                    }}
                  >
                    $90
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        backgroundColor: theme.palette.colorthree.main,
                        fontWeight: "600",
                      },
                      "&:active": {
                        backgroundColor: theme.palette.primary.main,
                        fontWeight: "600",
                        color: "white",
                      },
                    }}
                  >
                    $20
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      background: theme.palette.colorthree.main,
                      fontWeight: "600",
                      color: theme.palette.heading.main,
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    2 to 5 USERS
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        backgroundColor: theme.palette.colorthree.main,
                        fontWeight: "600",
                      },
                      "&:active": {
                        backgroundColor: theme.palette.primary.main,
                        fontWeight: "600",
                        color: "white",
                      },
                    }}
                  >
                    $210
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        backgroundColor: theme.palette.colorthree.main,
                        fontWeight: "600",
                      },
                      "&:active": {
                        backgroundColor: theme.palette.primary.main,
                        fontWeight: "600",
                        color: "white",
                      },
                    }}
                  >
                    $120
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        backgroundColor: theme.palette.colorthree.main,
                        fontWeight: "600",
                      },
                      "&:active": {
                        backgroundColor: theme.palette.primary.main,
                        fontWeight: "600",
                        color: "white",
                      },
                    }}
                  >
                    $30
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      background: theme.palette.colorthree.main,
                      fontWeight: "600",
                      color: theme.palette.heading.main,
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    6 to 20 USERS
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",

                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        backgroundColor: theme.palette.colorthree.main,
                        fontWeight: "600",
                      },
                      "&:active": {
                        backgroundColor: theme.palette.primary.main,
                        fontWeight: "600",
                        color: "white",
                      },
                    }}
                  >
                    $400
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        backgroundColor: theme.palette.colorthree.main,
                        fontWeight: "600",
                      },
                      "&:active": {
                        backgroundColor: theme.palette.primary.main,
                        fontWeight: "600",
                        color: "white",
                      },
                    }}
                  >
                    $250
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        backgroundColor: theme.palette.colorthree.main,
                        fontWeight: "600",
                      },
                      "&:active": {
                        backgroundColor: theme.palette.primary.main,
                        fontWeight: "600",
                        color: "white",
                      },
                    }}
                  >
                    $50
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      background: theme.palette.colorthree.main,
                      fontWeight: "600",
                      color: theme.palette.heading.main,
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Above 20 USERS
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        backgroundColor: theme.palette.colorthree.main,
                        fontWeight: "600",
                      },
                      "&:active": {
                        backgroundColor: theme.palette.primary.main,
                        fontWeight: "600",
                        color: "white",
                      },
                    }}
                  >
                    Custom Price
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "whitesmoke",
                      color: "gray",
                    }}
                  >
                    NA
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      border: "1px solid lightgray",
                      backgroundColor: "whitesmoke",
                      color: "gray",
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    NA
                  </Grid>
                </Grid>
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: "700",
                    color: theme.palette.heading.main,
                    textAlign: "center",
                    margin: "10px 0",
                    textAlign: "center",
                    padding: "20px",
                  }}
                >
                  Select Your plan
                </Typography>
              </>
           
            </Grid>
          </Grid> */}
        </Box>
      </Container>
    </Box>
  );
};

export default Pricing;
