import React, { useState } from "react";
import { Box, Button, Typography, Modal, TextField } from "@mui/material";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const PerformanceComparisonModal = ({ performanceReportType, onCompare }) => {
  const [open, setOpen] = useState(false);
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCompare = () => {
    handleClose();
    onCompare({ input1, input2 });
  };

  return (
    <div>
      <Button startIcon={<CompareArrowsIcon />} onClick={handleOpen}>
        {performanceReportType == "Performance tracking vs target"
          ? "Change Date range"
          : "Compare"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Select Inputs for Comparison
          </Typography>

          {performanceReportType === "Performance tracking week on week" && (
            <>
              <p>First Week Start Date</p>
              <TextField
                type="date"
                fullWidth
                sx={{ mt: 1 }}
                onChange={(e) => setInput1(e.target.value)}
              />
              <p>Second Week Start Date</p>
              <TextField
                type="date"
                fullWidth
                sx={{ mt: 1 }}
                onChange={(e) => setInput2(e.target.value)}
              />
            </>
          )}

          {performanceReportType === "Performance tracking month on month" && (
            <>
              <p>First Month Start Date</p>
              <TextField
                type="month"
                fullWidth
                sx={{ mt: 1 }}
                onChange={(e) => setInput1(e.target.value)}
              />
              <p>Second Month Start Date</p>
              <TextField
                type="month"
                fullWidth
                sx={{ mt: 1 }}
                onChange={(e) => setInput2(e.target.value)}
              />
            </>
          )}

          {performanceReportType ===
            "Performance tracking this year vs last year" && (
            <>
              <p>Year to Compare</p>
              <>
                <TextField
                  label="Year to Compare"
                  type="number"
                  fullWidth
                  sx={{ mt: 2 }}
                  onChange={(e) => setInput1(e.target.value)}
                />
              </>
            </>
          )}

          {performanceReportType ===
            "Performance tracking this month vs last year same month" && (
            <>
              <p>Month to Compare</p>
              <TextField
                type="month"
                fullWidth
                sx={{ mt: 1 }}
                onChange={(e) => setInput1(e.target.value)}
              />
            </>
          )}

          {performanceReportType ===
            "Performance tracking this week vs last year same week" && (
            <>
              <p>Week Start Date</p>
              <TextField
                type="date"
                fullWidth
                sx={{ mt: 1 }}
                onChange={(e) => setInput1(e.target.value)}
              />
            </>
          )}

          {performanceReportType === "Performance tracking vs target" && (
            <>
              <p>Custom Date Range Start</p>
              <TextField
                type="date"
                fullWidth
                sx={{ mt: 1 }}
                onChange={(e) => setInput1(e.target.value)}
              />
              <p>Custom Date Range End</p>
              <TextField
                type="date"
                fullWidth
                sx={{ mt: 1 }}
                onChange={(e) => setInput2(e.target.value)}
              />
            </>
          )}

          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            onClick={handleCompare}
          >
            Compare
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default PerformanceComparisonModal;

// import React, { useState } from "react";
// import { Box, Button, Typography, Modal, TextField } from "@mui/material";
// import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
// };

// const PerformanceComparisonModal = ({ performanceReportType, onCompare }) => {
//   const [open, setOpen] = useState(false);
//   const [input1, setInput1] = useState("");
//   const [input2, setInput2] = useState("");

//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const handleCompare = () => {
//     handleClose();

//     onCompare({ input1, input2 });
//   };

//   return (
//     <div>
//       <Button startIcon={<CompareArrowsIcon />} onClick={handleOpen}>
//         Compare
//       </Button>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <Typography id="modal-modal-title" variant="h6" component="h2">
//             Select Inputs for Comparison
//           </Typography>

//           {performanceReportType === "Performance tracking week on week" && (
//             <>
//               <TextField
//                 label="First Week Start Date"
//                 type="date"
//                 fullWidth
//                 sx={{ mt: 2 }}
//                 onChange={(e) => setInput1(e.target.value)}
//               />
//               <TextField
//                 label="Second Week Start Date"
//                 type="date"
//                 fullWidth
//                 sx={{ mt: 2 }}
//                 onChange={(e) => setInput2(e.target.value)}
//               />
//             </>
//           )}

//           {performanceReportType === "Performance tracking month on month" && (
//             <>
//               <TextField
//                 label="First Month Start Date"
//                 type="month"
//                 fullWidth
//                 sx={{ mt: 2 }}
//                 onChange={(e) => setInput1(e.target.value)}
//               />
//               <TextField
//                 label="Second Month Start Date"
//                 type="month"
//                 fullWidth
//                 sx={{ mt: 2 }}
//                 onChange={(e) => setInput2(e.target.value)}
//               />
//             </>
//           )}

//           {performanceReportType ===
//             "Performance tracking this year vs last year" && (
//             <>
//               <TextField
//                 label="Year to Compare"
//                 type="number"
//                 fullWidth
//                 sx={{ mt: 2 }}
//                 onChange={(e) => setInput1(e.target.value)}
//               />
//             </>
//           )}

//           {performanceReportType ===
//             "Performance tracking this month vs last year same month" && (
//             <>
//               <TextField
//                 label="Month to Compare"
//                 type="month"
//                 fullWidth
//                 sx={{ mt: 2 }}
//                 onChange={(e) => setInput1(e.target.value)}
//               />
//             </>
//           )}

//           {performanceReportType ===
//             "Performance tracking this week vs last year same week" && (
//             <>
//               <TextField
//                 label="Week Start Date"
//                 type="date"
//                 fullWidth
//                 sx={{ mt: 2 }}
//                 onChange={(e) => setInput1(e.target.value)}
//               />
//             </>
//           )}

//           {performanceReportType === "Performance tracking vs target" && (
//             <>
//               <TextField
//                 label="Custom Date Range Start"
//                 type="date"
//                 fullWidth
//                 sx={{ mt: 2 }}
//                 onChange={(e) => setInput1(e.target.value)}
//               />
//               <TextField
//                 label="Custom Date Range End"
//                 type="date"
//                 fullWidth
//                 sx={{ mt: 2 }}
//                 onChange={(e) => setInput2(e.target.value)}
//               />
//             </>
//           )}

//           <Button
//             variant="contained"
//             fullWidth
//             sx={{ mt: 3 }}
//             onClick={handleCompare}
//           >
//             Compare
//           </Button>
//         </Box>
//       </Modal>
//     </div>
//   );
// };

// export default PerformanceComparisonModal;
